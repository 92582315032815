import React, { memo } from 'react'
import { IconButton, IconButtonProps, alpha, styled } from '@mui/material'

interface StyleProps {
  themeVariant: 'light' | 'dark'
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'themeVariant',
})<StyleProps>(({ themeVariant, theme }) => ({
  width: '54px',
  height: '54px',
  zIndex: 10,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: 0,
  ...(themeVariant === 'light' && {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    svg: {
      path: {
        fill: theme.palette.primary.main,
      },
    },
  }),
  ...(themeVariant === 'dark' && {
    color: 'white',
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 1),
    ['&:hover']: {
      backgroundColor: alpha(theme.palette.secondary.main, 1),
    },
    svg: {
      path: {
        fill: 'white',
      },
    },
  }),
}))

const NavButton = React.forwardRef<IconButtonProps, HTMLButtonElement>(
  (props, ref) => {
    return <StyledIconButton {...props} ref={ref} />
  }
)

export default memo(NavButton)
