// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Box,
  BoxProps,
  Container,
  Stack,
  styled,
  useTheme,
} from '@mui/material'

// atoms
import InternalLink from '@atoms/links/InternalLink'

// molecules
import RecentArtworkSwiper from '@molecules/swipers/RecentArtworkSwiper'

export interface SectionRecentArtworkProps extends BoxProps {
  heading: string
  viewAllLabel: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  position: 'relative',
  overflow: 'hidden',
  ['&:before']: {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '540px',
    [theme.breakpoints.up('sm')]: {
      height: '440px',
    },
    [theme.breakpoints.up('md')]: {
      height: '540px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '640px',
    },
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    left: 0,
    right: 0,
  },
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(10),
  },
}))

const AllLink = styled(InternalLink)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightThin,
  letterSpacing: '0.2rem',
  whiteSpace: 'nowrap',
}))

const SectionRecentArtwork = ({
  heading,
  viewAllLabel,
  ...props
}: SectionRecentArtworkProps) => {
  const theme = useTheme()

  const data = useStaticQuery<Queries.SectionRecentArtworkQuery>(graphql`
    query SectionRecentArtwork {
      artworkLanding: datoCmsLanding(originalId: { eq: "63886058" }) {
        slug
      }
      allDatoCmsArtwork {
        totalCount
      }
    }
  `)
  return (
    <Wrapper component="section" {...props}>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={{ xs: 3, sm: 4, lg: 5, xl: 6 }}
          position="relative"
        >
          <Box
            typography="sectionHeadingLarge"
            component="h2"
            m={0}
            mr={4}
            sx={{
              lineHeight: 1,
            }}
          >
            {heading}
          </Box>
          <AllLink slug={data.artworkLanding.slug}>
            {viewAllLabel}{' '}
            <Box color="primary.main" component="span">
              ({data.allDatoCmsArtwork.totalCount})
            </Box>
          </AllLink>
        </Stack>
        <RecentArtworkSwiper />
      </Container>
    </Wrapper>
  )
}

export default memo(SectionRecentArtwork)
