// npm
import React, { memo, useRef } from 'react'
import { Box, BoxProps, styled, useTheme } from '@mui/material'
import { Swiper, SwiperProps } from 'swiper/react'
import SwiperCore, { Navigation, Scrollbar, FreeMode } from 'swiper'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'

// components
import NavButton from '@atoms/buttons/NavButton'

// images
import ChevronRight from '@images/icons/chevron-right.svg'

export interface NavScrollbarSwiperProps {
  children: React.ReactNode
  swiperClass: string
  wrapperProps?: BoxProps
  swiperProps?: SwiperProps
}

interface scrollbarPositionProps {
  scrollbarWidth: number
  navSpace: number
  buttonSize: number
  buttonSpace: number
}

const scrollbarWidths = {
  xs: 300,
  md: 480,
  lg: 780,
}

const scrollbarProps = {
  navSpace: 50,
  buttonSize: 50,
  buttonSpace: 0,
}

const getNavContainerWidth = ({
  scrollbarWidth,
  navSpace,
  buttonSize,
  buttonSpace,
}: scrollbarPositionProps): number => {
  const buttonContainerWidth = buttonSize * 2 + buttonSpace
  return scrollbarWidth + buttonContainerWidth + navSpace
}
const getScrollbarPoisition = ({
  scrollbarWidth,
  navSpace,
  buttonSize,
  buttonSpace,
}: scrollbarPositionProps): number => {
  const buttonContainerWidth = buttonSize * 2 + buttonSpace
  return -(scrollbarWidth / 2 + (buttonContainerWidth + navSpace) / 2)
}

const SwiperWrapper = styled(Box)(({ theme }) => ({
  ['.swiper']: {
    overflow: 'visible',
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      paddingBottom: theme.spacing(12),
    },
  },
  ['.swiper-wrapper']: {
    alignItems: 'stretch',
  },
  ['.swiper-slide']: {
    height: 'auto',
    display: 'flex',
    alignItems: 'stretch',
  },
  ['.swiper-horizontal > .swiper-scrollbar']: {
    width: `${scrollbarWidths.xs}px`,
    left: '50%',
    height: '1px',
    backgroundColor: 'rgba(0,0,0,.12)',
    transform: 'translateX(-50%)',
    bottom: `${scrollbarProps.buttonSize / 2}px`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      bottom: `${scrollbarProps.buttonSize / 2 + 60}px`,
    },
    [theme.breakpoints.up('md')]: {
      width: `${scrollbarWidths.md}px`,
      transform: `translateX(${getScrollbarPoisition({
        ...scrollbarProps,
        scrollbarWidth: scrollbarWidths.md,
      })}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `${scrollbarWidths.lg}px`,
      transform: `translateX(${getScrollbarPoisition({
        ...scrollbarProps,
        scrollbarWidth: scrollbarWidths.lg,
      })}px)`,
    },
    ['.swiper-scrollbar-drag']: {
      height: '3px',
      marginTop: '-1px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      cursor: 'pointer',
    },
  },
  ['.swiper-button-disabled']: {
    opacity: 0.5,
  },
}))

const NavWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: `${getNavContainerWidth({
      ...scrollbarProps,
      scrollbarWidth: scrollbarWidths.md,
    })}px`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `${getNavContainerWidth({
      ...scrollbarProps,
      scrollbarWidth: scrollbarWidths.lg,
    })}px`,
  },
  height: `${scrollbarProps.buttonSize}px`,
}))

const StyledNavButton = styled(NavButton)(({ theme }) => ({
  width: `${scrollbarProps.buttonSize}px`,
  height: `${scrollbarProps.buttonSize}px`,
  zIndex: 10,
}))

const NavScrollbarSwiper = ({
  children,
  swiperClass,
  wrapperProps,
  swiperProps,
}: NavScrollbarSwiperProps) => {
  const theme = useTheme()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  SwiperCore.use([Navigation, Scrollbar, FreeMode])
  return (
    <SwiperWrapper {...wrapperProps}>
      <Swiper
        onInit={(swiper) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = prevRef.current
          // @ts-ignore
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        slidesPerView={1}
        observer={true}
        observeParents={true}
        scrollbar
        freeMode={{
          sticky: true,
        }}
        className={swiperClass}
        breakpoints={{
          [theme.breakpoints.values.xs]: {
            slidesPerView: 1,
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 2,
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: 3,
          },
        }}
        {...swiperProps}
      >
        {children}
        <NavWrapper>
          <StyledNavButton
            aria-label="previous"
            themeVariant="light"
            ref={prevRef}
            sx={{
              borderRightWidth: 0,
              svg: {
                transform: 'rotate(180deg)',
              },
            }}
          >
            <ChevronRight />
          </StyledNavButton>
          <StyledNavButton aria-label="next" themeVariant="light" ref={nextRef}>
            <ChevronRight />
          </StyledNavButton>
        </NavWrapper>
      </Swiper>
    </SwiperWrapper>
  )
}

export default memo(NavScrollbarSwiper)
