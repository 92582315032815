// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, useTheme } from '@mui/material'
import { SwiperSlide } from 'swiper/react'

// components
import ArtworkCard from '@molecules/cards/ArtworkCard'
import NavScrollbarSwiper from '@molecules/swipers/layouts/NavScrollbarSwiper'

const RecentArtworkSwiper = () => {
  const theme = useTheme()
  const data = useStaticQuery<Queries.RecentArtworkSwiperQuery>(graphql`
    query RecentArtworkSwiper {
      allDatoCmsArtwork(
        sort: {
          fields: [availability___position, meta___createdAt]
          order: [ASC, DESC]
        }
        limit: 10
      ) {
        edges {
          node {
            ...artworkCard
          }
        }
      }
    }
  `)

  return (
    <NavScrollbarSwiper
      swiperClass="recent-artwork-swiper"
      swiperProps={{
        spaceBetween: 0,
        breakpoints: {
          [theme.breakpoints.values.xs]: {
            slidesPerView: 1,
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 2,
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: 3,
          },
          [theme.breakpoints.values.xl]: {
            slidesPerView: 4,
          },
        },
      }}
    >
      {data.allDatoCmsArtwork.edges.map((item: any) => {
        const artwork: Queries.DatoCmsArtwork = item.node
        return (
          <SwiperSlide key={artwork.originalId}>
            <Box color="text.primary">
              <ArtworkCard artwork={artwork} />
            </Box>
          </SwiperSlide>
        )
      })}
    </NavScrollbarSwiper>
  )
}

export default memo(RecentArtworkSwiper)
